<template>
  <div class="aside-box">
    <div class="logo" :class="isCollapse ? 'small-logo' : ''">
      <img src="@/assets/images/logo.png" alt="" v-if="!isCollapse" />
      <img src="@/assets/images/logo2.png" v-else alt="" />
    </div>
    <el-menu
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      :router="true"
      :default-active="defaultActive"
    >
      <el-menu-item
        v-for="item in $router.options.routes.filter(x=>x.meta)"
        :index="item.path"
        :key="item.meta.title"
      >
        <img
          :src="
            item.path == defaultActive
              ? require('@/assets/images/' + item.meta.ActiveDefaultIcon + '')
              : require('@/assets/images/' + item.meta.defaultIcon + '')
          "
          alt=""
        />
        <span slot="title">{{ item.meta.title }}</span>
      </el-menu-item>
    </el-menu>
    <div class="bottom">
      <i
        @click="isCollapse = !isCollapse"
        :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      defaultActive: "",
    };
  },
  watch: {
    $route(newVal, old) {
      if (newVal.name != old.name) {
        this.defaultActive =
          newVal.matched[0].path == "" ? "/" : newVal.matched[0].path;
      }
    },
  },
  mounted() {
    this.defaultActive =
      this.$route.matched[0].path == "" ? "/" : this.$route.matched[0].path;
  },
};
</script>

<style lang="scss" scoped>
.aside-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 2px -10px 6px 3px #000;
  .el-menu {
    flex: 1;
  }
  .bottom {
    padding: 12px 20px;
    color: rgba(55, 69, 103, 0.45);
    font-size: 18px;
    border-top: 0.5px solid rgba(124, 141, 167, 0.1);
  }
}
.logo {
  width: 142px;
  padding: 10px 13px;
  margin-bottom: 32px;
  img {
    width: 100%;
    vertical-align: middle;
  }
}
.small-logo {
  width: 38px;
  img {
    width: 100%;
    vertical-align: middle;
  }
}
.el-menu-vertical-demo {
  border-right: 1px solid transparent;
}
.el-menu-item {
  font-size: 16px;
  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}
.el-menu-item.is-active {
  border-right: 2px solid #00a0e9;
  background: rgba(0, 160, 233, 0.1);
}
</style>