<template>
  <div>
    <div class="head-info">
      <el-row>
        <el-col :span="20"> </el-col>
        <el-col
          v-if="$route.matched[0].meta.title"
          :span="4"
          class="head-right"
        >
          <el-row type="flex" justify="end" class="head-right-content">
            <el-col :span="2" class="head-right-i right-margin-27">
              <img src="@/assets/images/search.png" alt="" />
            </el-col>
            <el-col :span="2" class="head-right-i right-margin-17">
              <img src="@/assets/images/message.png" alt=""
            /></el-col>
            <el-col :span="12" class="head-user">
              <el-menu ref="menu" class="head-right-i" @select="handleSelect">
                <el-submenu index="2">
                  <template slot="title"
                    ><span class="avatar"
                      ><img
                        :src="staticURL + userInfo.avatar"
                        alt=""
                        v-if="userInfo.avatar" /><img
                        v-else
                        src="@/assets/images/default_avatar.png"
                    /></span>
                    <span class="user">{{ userInfo.name }}</span></template
                  >
                  <el-menu-item index="2-1">退出登录</el-menu-item>
                </el-submenu>
              </el-menu>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="card-box">
      <el-tabs
        type="card"
        @tab-click="handleClick"
        @tab-remove="removeTab"
        closable
        :value="$route.fullPath"
      >
        <el-tab-pane
          v-for="item in activeMenus"
          :key="item.title"
          :label="item.title"
          :name="item.fullPath"
        ></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { URL } from "@/util/request";
export default {
  data() {
    return {
      userInfo: {},
      staticURL: URL.static,
    };
  },
  watch: {
    $route() {
      if (!this.userInfo && localStorage.getItem('userInfo')) {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      }
    },
  },
  computed: {
    ...mapState({
      activeMenus: (state) => state.menu.active_menus,
    }),
  },
  mounted() {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }
  },
  methods: {
    handleSelect() {
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('auditor');
      this.$router.go(0);
    },

    handleClick(tab, event) {
      this.$router.replace(tab.name);
    },
    removeTab(tab) {
      this.$store.dispatch("remove_active_menus", tab).then((res) => {
        if (this.activeMenus.length == 0) {
          this.$router.replace("/");
        } else if (tab == this.$route.path) {
          this.$router.replace(
            this.activeMenus[this.activeMenus.length - 1].path
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.head-info {
  box-shadow: 2px 0px 3px 1px rgba(0, 21, 41, 0.12);
  padding: 0 30px;
  box-sizing: border-box;
  margin-bottom: 11px;
}
.el-row {
  height: 48px;
  line-height: 48px;
  background: #fff;
  .el-col {
    height: 100%;
  }
  .head-right {
    height: 100%;
    .head-right-content {
      .head-user {
        width: auto;
      }
      .head-right-i,
      .head-right-avatar {
        margin-right: 27px;
        i {
          font-size: 18px;
          vertical-align: middle;
          color: rgba(55, 69, 103, 0.65);
        }
        > img {
          width: 100%;
          vertical-align: middle;
        }
      }
      .head-right-i:last-child {
        margin-right: 0;
      }
      .avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        img {
          width: 100%;
          vertical-align: top;
        }
      }
      .user {
        font-size: 14px;
        color: rgba(55, 69, 103, 0.65);
        vertical-align: middle;
        margin-left: 8px;
      }
    }
  }
  /deep/.el-submenu__title {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 !important;
    height: 100%;
    line-height: 48px;
    /deep/.el-submenu__icon-arrow {
      display: none;
    }
  }
  /deep/.el-submenu .el-menu-item {
    width: 100%;
    min-width: 100%;
    padding: 0 !important;
    text-align: center;
  }
  /deep/.el-menu--inline {
    margin-top: 1px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    width: 119px;
  }
  .el-menu {
    border-right: 0;
    z-index: 10;
    height: 100%;
    line-height: 48px;
    .el-submenu {
      height: 100%;
      line-height: 48px;
      .el-menu {
      }
    }
  }
}
.card-box {
  padding: 0 30px;
}
::v-deep .el-tabs--card > .el-tabs__header {
  margin-bottom: 0;
  border-bottom: 0;
}
::v-deep .el-tabs__item {
  font-weight: 400;
}
</style>
