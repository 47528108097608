<template>
  <el-container>
    <el-aside width='auto'>
        <industry-aside></industry-aside>
    </el-aside>
    <el-container>
      <el-header><industry-head></industry-head></el-header>
      <el-main> <industry-main></industry-main></el-main>
    </el-container>
  </el-container>
</template>
<script>
import { IndustryMain, IndustryHead,IndustryAside } from "./components";
export default {
  name: "layout",
  components: {
    IndustryMain,
    IndustryHead,
    IndustryAside
  },
};
</script>
<style scoped lang="scss">
.el-container {
  width: 100%;
  height: 100%;
}
.el-aside{
  box-shadow: 0 0px 6px 3px rgba(0, 21, 41, 0.12);
  z-index: 11;
}
.el-header {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  height: 101px !important;
  box-shadow:10px 0px 4px 3px rgba(0, 21, 41, 0.12);
  // background: #fff;
  z-index: 10;
}
.el-main {
  position: relative;
  padding: 0;
}

</style>